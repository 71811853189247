import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { IconButton, Typography, Snackbar } from "@material-ui/core"

import Brightness7Icon from "@material-ui/icons/Brightness7"
import Brightness4Icon from "@material-ui/icons/Brightness4"
import CloseIcon from "@material-ui/icons/Close"

import { Container } from "../container"
import { Wrapper, HeaderWrapper } from "./styles"

const Header = ({ siteTitle }) => {
  let websiteTheme
  if (typeof window !== "undefined") {
    websiteTheme = window.__theme
  }

  const [theme, setTheme] = useState(websiteTheme)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => {
      setTheme(window.__theme)
    }
  }, [])

  const themeToggle = () => {
    setCount(count + 1)
    window.__setPreferredTheme(websiteTheme === "dark" ? "light" : "dark")
    switch (count) {
      default:
        break
      case 5:
        setText("do you like changing my colors ?")
        setOpen(true)
        break
      case 10:
        setText("10 times?? whooo...")
        setOpen(true)
        break
      case 15:
        setText("are you gonna check the list or just play with me?")
        setOpen(true)
        break
      case 20:
        setText("Im getting tired ...")
        setOpen(true)
        break
      case 25:
        setText("How long can you do this for?")
        setOpen(true)
        break
      case 35:
        setText("stoppp pleaseeeeeee")
        setOpen(true)
        break
      case 50:
        setText("*dead*")
        setOpen(true)
        setCount(0)
        break
    }
  }

  const [open, setOpen] = useState(false)
  const [text, setText] = useState()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <HeaderWrapper>
      <Container>
        <Wrapper>
          <Typography component="h1" variant="h6" style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: "var(--textTitleInverse)",
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="Theme Color"
            component="span"
            onClick={themeToggle}
          >
            {theme === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={text}
            severity="info"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Wrapper>
      </Container>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
